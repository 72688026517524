.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

html {
  width: 100vw;
  overflow: hidden;
}

#menu-top-bar {
  height: 50px;
  top: 0px;
  left: 0px;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 200;
  align-items: center;
  background-color: white;
  border-width: 0px 0px 1px 0px;
  border-color: black;
  border-style: solid;
}

.menu-top-bar-button {
  cursor: pointer;
}

.menu-top-bar-horizontal-line {
  width: 30px;
  padding-top:4px;
  margin-left: 4px;
  margin-right: 4px;
}

.sections-container {
  flex: 1;
  overflow-y: auto;
  width: 100vw;
  overflow-x: hidden;
}

.subpage-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px - 80px);
  align-items: center;
  /* justify-content: center; */
  padding: 40px 4vw 40px 4vw
}

.subpage-header {
  font-size: 5vw;
  color: white;
  font-family: 'Rubik Distressed';
  font-weight: 400;
  /* font-family: 'Assistant', sans-serif; */
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0px 25px 10px 25px;
  margin-bottom: 20px;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .subpage-header {
    font-size: 42px;
  }
}

.subpage-content {
  background-color: rgba(255, 255, 255, 0.89);
  width: 85vw;
  padding: 20px 4vw 20px 4vw;
  border: 1px solid black;
  border-radius: 15px;
  font-family: 'Syne Mono', monospace;
  text-align: left;
  font-size: 18px;
  min-height: 60vh;
}

@media (max-width: 600px) {
  .subpage-content {
    font-size: 16px;
  }
}

.discography-single-container {
  height: 64vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #dyskografia-content {
    flex-direction: column
  }
}

.album-details {
  padding: 20px
}

.album-title > h2 {
  padding-top: 0px;
}


.media-video {
  /* width: 560px; */
  /* width: 80vw;
  max-width: 280px; */
  width: 280px;
  margin: 10px;
  /* height: 315px; */
  aspect-ratio: 16 / 9;
}

@media (max-width: 700px) {
  .media-video {
    width: 80vw;
  }
}

.main-page-header {
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 3vh;
  z-index: 5
}

.main-page-header > h1 {
  font-size: 8.5vw;
  color: white;
  font-family: 'Rubik Distressed';
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100vw;
}

@media (max-width: 700px) {
  .main-page-header > h1 {
    font-size: 9.5vw;
  }
}

@media (max-width: 700px) {
  .discography-single-container {
    flex-direction: column;
  }
}

.head {
  position: absolute;
  top: 10px;
  width: 90vh;
  z-index: 0;
  opacity: 0.9;
  filter:brightness(80%)
}

.social-media-icons-container {
  display:flex;
  margin-top: 40vh;
  margin-bottom: 0vh;
  z-index: 5;
}

.social-media-icon {
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid white;
  border-radius: 4vw;
  width: 8vw;
  height: 8vw;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.social-media-icon:hover {
  opacity:0.7;
  background-color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 600px) {
  #menu-top-bar {
    display: none;
  }

  .subpage-container {
    min-height: calc(100vh - 80px);
  }
}

@media (max-width: 1000px) {
  .social-media-icon {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}

.social-media-icon > a > img {
  cursor: pointer;
  width: 4vw;
  min-width: 40px;
  filter: invert()
}

#oferta-pobierz-button {
  margin-top: 10px;
  padding: 10px 65px 10px 65px;
  border-radius: 15px;
  border: 1px solid black;
  cursor: pointer;
}

#oferta-pobierz-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.album-card {
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.album-card {
  width: 75vw;
  height: 400px;
}

.spotifyLink > img {
  margin-left: 15px
}

@media (max-width: 600px) {
  .album-main-flex {
    flex-direction: column;
  }

  .album-card {
    height: min-content
  }

  .spotifyLink {
    flex-direction: column;
    margin-top: 20px;
  }

  .spotifyLink > img {
    margin-top: 10px;
    margin-left: 0px
  }

  .albumcoverContainer img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 600px) {
  .albumcoverContainer > img {
    height: 400px;
  }
}

.swiper-button-next {
  filter: grayscale(100%)
}

.swiper-button-prev {
  filter: grayscale(100%)
}

#spotify-icon {
  filter: grayscale(100%)
}

#spotify-icon:hover {
  cursor: pointer;
  filter: grayscale(40%)
}

.image_download_button {
  background-color: 'white'
}

.image_download_button:hover {
  background-color: pink;
}

.button-kup-album {
  background-color: black;
  border: 1px solid black
}

.button-kup-album:hover {
  cursor: pointer;
  border: 1px solid red;
  background-color: rgb(60, 0, 85);
}

.button-kup-album > div > img {
  width: 100px
}

@media (max-width: 600px) {
  .button-kup-album > div > img {
    width: 80px
  }
}

